const className = 'block-truncated';
const truncatedBlocks: NodeListOf<HTMLElement> = document.querySelectorAll(
	`.${className}`,
);

for (const block of truncatedBlocks) {
	const toggle = (event: MouseEvent) => {
		event.preventDefault();
		block.classList.remove(className);
		globalThis.setTimeout(() => {
			const headerHeight = 48;
			const stickyNavigation: HTMLElement | null =
				document.querySelector('.sticky');

			if (stickyNavigation) {
				window.scrollTo(
					0,
					block.offsetTop -
						headerHeight -
						(stickyNavigation?.offsetHeight ?? 0),
				);
			}

			block.removeEventListener('click', toggle);
		}, 10);
	};

	block.addEventListener('click', toggle);
}
