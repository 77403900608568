const currentPageId = document.documentElement?.classList.item(0);
const navigationLinks = document.querySelectorAll('.nav-list > li > a');
const skipLink: HTMLElement | null = document.querySelector('#skiplink');
const skipLinkHidden =
	!skipLink || (skipLink.offsetWidth === 0 && skipLink.offsetHeight === 0);

if (skipLinkHidden) {
	const navigation = document.querySelector('.main-nav');
	if (navigation) {
		navigation.insertAdjacentHTML('beforeend', '<div id="lava"></div>');
	}
}

const lavaEffect = (event: Event) => {
	if (skipLinkHidden && currentPageId) {
		let element: EventTarget | null = event.target;
		if (/mouseleave|focusout/.test(event.type)) {
			element = document.querySelector(`#nav-${currentPageId} > a`);
		}

		const lava: HTMLElement | null = document.querySelector('#lava');
		if (lava) {
			lava.style.height =
				element instanceof HTMLAnchorElement
					? `${element.offsetHeight - 10}px`
					: '';
			lava.style.top =
				element instanceof HTMLAnchorElement
					? `${element.offsetTop + 5}px`
					: '';
		}
	}
};

// Add hover and focus events to navigation links
for (const link of navigationLinks) {
	link.addEventListener('mouseenter', lavaEffect);
	link.addEventListener('mouseleave', lavaEffect);
	link.addEventListener('focusin', lavaEffect);
	link.addEventListener('focusout', lavaEffect);
}

// Initialize lava effect
const firstLink: HTMLAnchorElement | null = document.querySelector(
	'.nav-list li:first-child a',
);
const leaveEvent = new MouseEvent('mouseleave', {
	bubbles: true,
	cancelable: false,
});
firstLink?.dispatchEvent(leaveEvent);
