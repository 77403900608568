const preview: SVGTextElement | null = document.querySelector(
	'.newsletter-preview',
);

if (preview) {
	preview.dataset.initial = preview.textContent ?? '';
	const setValue = (value: string) => {
		preview.textContent =
			value === '' ? (preview.dataset.initial ?? '') : value;
	};

	const input: HTMLInputElement | null = document.querySelector('#email');
	if (input) {
		setValue(input.value);
		input.addEventListener('input', () => {
			setValue(input.value);
		});
	}
}
