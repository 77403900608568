const activeClass = 'with-nav';

const toggleNavigation = () => {
	document.documentElement.classList.toggle(activeClass);
};

// Toggle navigation via skiplink
const skiplink: HTMLAnchorElement | null = document.querySelector('#skiplink');
skiplink?.addEventListener('click', (event: MouseEvent) => {
	event.preventDefault();
	toggleNavigation();
});

// Handle close by Escape key
document.addEventListener('keyup', (event: KeyboardEvent) => {
	if (
		event.key === 'Escape' &&
		document.documentElement.classList.contains(activeClass)
	) {
		toggleNavigation();
	}
});

// Prevent clicking the current menu link
const links: NodeListOf<HTMLAnchorElement> =
	document.querySelectorAll('.nav-list a');
for (const link of links) {
	if (link.href === location.href) {
		link.addEventListener('click', (event: MouseEvent) => {
			event.preventDefault();
			link.blur();
			if (document.documentElement.classList.contains(activeClass)) {
				toggleNavigation();
			}
		});
	}
}
